<template>
  <v-container fluid>
    <!-- <Breadcrumbs /> -->
    <!-- Knowlarity Creds Input -->
    <v-card class="rounded secondarygrad mx-4">
      <v-row style="margin: 0px">
        <v-col>
          <v-card
            color="transparent"
            elevation="0"
            style="padding: 10px; text-align: center !important"
          >
            <v-card-text style="margin-bottom: 25px">
              <!-- <h2 style="margin-bottom: 30px">
                      Lorem ipsum
                    </h2> -->
              <div align="center" style="margin-bottom: 10px; margin-top: 20%">
                <!-- <v-img
                        :src="logo2"
                        style="width: 250px;justify-content: center"
                      ></v-img> -->
                <h1 style="margin-bottom: 40px">Godial + Knowlarity</h1>
              </div>
              <!-- <h3 style="margin: 5px 0px">
                      Lorem ipsum
                    </h3> -->
              <div style="padding: 5px 15px; text-align: center">
                <h5
                  style="
                          line-height: 17px;
                          font-weight: 500;
                          font-size: 14px;
                        "
                >
                  Integrate GoDial with Knowlarity and enjoy features like Call
                  Recording, Call number masking and more. Find these details
                  from your knowlarity partner and enable it.
                </h5>

                <div style="text-align: left">
                  <p
                    style="
                            margin-top: 20px;
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 10px;
                          "
                  >
                    Features
                  </p>
                  1. Call Recording
                  <br />
                  2. Number masking
                  <br />
                  3. Incoming Calls (Integration under development)
                </div>
              </div>
            </v-card-text>
            <v-footer color="transparent" absolute>
              <v-flex style="text-align: center !important">
                <p
                  style="
                          font-size: 12px;
                          opacity: 0.9;
                          margin-bottom: 0px;
                        "
                >
                  Feel free to reach out to us at
                  <a href="mailto:support@godial.cc">support@godial.cc</a>
                  if you are interested in these features
                </p>
              </v-flex>
            </v-footer>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat class="transparent">
            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-layout row wrap>
                <v-flex d-flex justify-center style="padding-top: 22px"
                  >Enable Knowlarity
                </v-flex>
                <v-flex d-flex justify-center>
                  <v-switch
                    v-model="knowlarity.enabled"
                    color="primary"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card>
          <v-card
            color="transparent"
            elevation="0"
            style="padding: 15px 50px; text-align: center"
          >
            <v-card-title primary-title class="justify-center"
              >Knowlarity Credentials</v-card-title
            >

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-text-field
                v-bind:disabled="!knowlarity.enabled"
                v-model="knowlarity.authorization"
                label="Authorization Key"
                type="text"
                outlined
                dense
                prepend-inner-icon="perm_identity"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-text-field
                v-bind:disabled="!knowlarity.enabled"
                v-model="knowlarity.xapikey"
                label="X-Api Key"
                type="text"
                outlined
                dense
                prepend-inner-icon="vpn_key"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-text-field
                v-bind:disabled="!knowlarity.enabled"
                v-model="knowlarity.knumber"
                label="Knumber Ex: +919999999999"
                type="text"
                outlined
                dense
                prepend-inner-icon="stay_primary_portrait"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-text-field
                v-bind:disabled="!knowlarity.enabled"
                v-model="knowlarity.callerId"
                label="Caller Id"
                type="text"
                outlined
                dense
                prepend-inner-icon="accessibility"
              ></v-text-field>
            </v-flex>

            <v-card-text>
              <v-flex
                xs12
                sm10
                offset-sm1
                md10
                offset-md1
                style="margin-bottom: 10px"
              >
                <v-btn
                  color="primarygrad"
                  dark
                  block
                  @click="setKnowlarityCompany"
                  >Save</v-btn
                >
              </v-flex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- Set Default Dialer -->
    <!-- <v-flex
      style="padding-top:10px;"
      v-if="knowlarityExists"
    >
      <v-row
        align="center"
        no-gutters
        style="height: 100%"
      >
        <v-flex
          xs12
          sm12
        >
          <v-card
            elevation="1"
            style="border-radius: 0px"
          >
            <v-row style="margin: 0px">
              <v-flex
                xs12
                sm12
                md12
                style="padding: 0px; margin: 0px; background-color: #fbfbfb"
              >
                <v-card
                  color="transparent"
                  elevation="0"
                  style="padding: 10px; text-align: center !important"
                >
                  <v-card-text style="margin-bottom: 25px">
                    <h2 style="margin-bottom: 30px">
                      Set Default Dialer To Knowlarity / Phone
                    </h2>
                    <div style="padding: 5px 15px; text-align: center">
                      <v-row
                        align="center"
                        justify="center"
                      >
                        <v-col cols="6">
                          <v-flex
                            xs12
                            sm12
                            md12
                            style="padding: 0px; margin: 0px"
                          >
                            <v-combobox
                              dense
                              outlined
                              small-chips
                              solo
                              :items="dialers"
                              item-text="name"
                              v-model="defaultDialer"
                            ></v-combobox>
                          </v-flex>
                          <v-btn
                            color="primary"
                            @click="setAgentDefaultDialer"
                          >Submit</v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-row>

          </v-card>
        </v-flex>
      </v-row>
    </v-flex> -->

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      knowlarity: {
        authorization: "",
        xapikey: "",
        knumber: "",
        callerId: "",
        enabled: false,
      },
      // dialers: [
      //   { name: "Phone Dialer", value: "Phone Dialer" },
      //   { name: "Knowlarity", value: "Knowlarity" },
      // ],
      defaultDialer: { name: "Phone Dialer", value: "Phone Dialer" },
      loader: false,
      // knowlarityExists: false,
    };
  },

  computed: {
    ...mapState(["ENDPOINT", "companyDetails"]),
  },
  created() {
    // this.objAssignKnowlarity();
    // this.knowlarityExistsCheck();
    this.refresh();
  },
  watch: {},
  methods: {
    async refresh() {
      await this.getKnowlarityDetails();
    },
    knowlarityExistsCheck() {
      if (
        typeof this.companyDetails.knowlarity != "undefined" &&
        this.companyDetails.knowlarity.enabled == true
      ) {
        this.knowlarityExists = true;
      } else {
        this.knowlarityExists = false;
      }
    },
    objAssignKnowlarity() {
      Object.assign(this.knowlarity, this.companyDetails.knowlarity);
    },
    async setKnowlarityCompany() {
      if (this.knowlarity.authorization == "") {
        return this.$swal({
          type: "warning",
          text: "Authorization Key Can't be Blank",
        });
      }

      if (this.knowlarity.xapikey == "") {
        return this.$swal({
          type: "warning",
          text: "X-api-key Key Can't be Blank",
        });
      }

      if (this.knowlarity.knumber == "") {
        return this.$swal({
          type: "warning",
          text: "Knumber Can't be Blank",
        });
      }

      if (this.knowlarity.callerId == "") {
        return this.$swal({
          type: "warning",
          text: "Knumber Can't be Blank",
        });
      }

      this.loader = true;

      let cloudObj = {
        companyId: this.$store.state.companyDetails.id,
      };

      cloudObj.config = this.knowlarity;

      cloudObj.provider = "Knowlarity";

      if (!this.knowlarityIdAvailable) {
        this.$http
          .post(`${this.ENDPOINT}/cloudTelephonies`, cloudObj)
          .then((respo) => {
            this.loader = false;
            this.refresh();
          })
          .catch((err) => {
            this.loader = false;
            console.log("err", err);
          });
      } else {
        this.$http
          .put(
            `${this.ENDPOINT}/cloudTelephonies/${this.knowlarityObjectId}`,
            cloudObj
          )
          .then((respo) => {
            this.loader = false;
            this.refresh();
            console.log("respo", respo);
          })
          .catch((err) => {
            this.loader = false;
            console.log("err", err);
          });
      }
    },
    async getKnowlarityDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/cloudTelephonies?filter[where][companyId]=${this.companyDetails.id}&filter[where][provider]=Knowlarity`
      );

      console.log("response Knowlarity get", resp.body);

      if (resp.body.length > 0) {
        this.knowlarity = resp.body[0].config;

        // console.log("this.lists", this.lists);

        // this.selectedList = this.lists.find((l) => {
        //   return l.id == this.exotel.incomingListId;
        // });

        // console.log("this.selectedList", this.selectedList);

        //this.selectedList = this.exotel.incomingListId;
        this.knowlarityIdAvailable = true;
        this.knowlarityObjectId = resp.body[0].id;
      } else {
        this.knowlarityIdAvailable = false;
      }
    },
  },
};
</script>

<style></style>
